<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">WELCOME TO YOU</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="redirect('/case')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container class="ma-0 pa-0">
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="12" xs="12" md="6" lg="3">
              <v-card>
                <v-toolbar color="indigo" dark>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>공유설정</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text style="height: 220px">
                  <p>다른 사람을 구성원으로 연결
                    하고자 하는 경우에는 그 사람
                    의 구글계정을 공유설정해야
                    합니다.</p>
                  <p>- 관리(대행)자 고유권한</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span class="text-body-1 mr-1 mt-1">공유관리</span>
                  <v-icon>open_in_browser</v-icon>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="3">
              <v-card>
                <v-toolbar color="light-blue" dark>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>업무부서</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text style="height: 220px">
                  <p>모든 정보는 업무부서에
                  귀속되며, 그 부서 전원에게
                  공개됩니다.</p>
                  <p>그러므로,  정보공개의 범위를
                  구별 하고자 하는 경우에는
                  반드시 별도의 업무부서를
                  생성해야 합니다.</p>
                  <p>- 관리(대행)자 고유권한</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span class="text-body-1 mr-1 mt-1">업무부서관리</span>
                  <v-icon>open_in_browser</v-icon>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="3">
              <v-card>
                <v-toolbar color="teal" dark>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>메뉴배치설정</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text style="height: 220px">
                  <p>화면보기방식, 업무부서순서,
                  관리그룹순서 등을 개인별로
                  최적화하여 사용할 수 있습니다.</p>
                  <p>회원님의 업무패턴에 적합하
                  도록 설정하십시오.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span class="text-body-1 mr-1 mt-1">환경설정</span>
                  <v-icon>open_in_browser</v-icon>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="3">
              <v-card>
                <v-toolbar color="pink" dark>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>솔루션이용안내</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text style="height: 220px">
                  <p>보다 자세한 이용법은
                  이용팁을 활용하시고,<br>
                  문의 사항은 주저없이
                  고객센터로 연락주십시오.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span class="text-body-1 mr-1 mt-1">이용팁</span>
                  <v-icon>open_in_browser</v-icon>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false
  }),

  mounted () {
    this.dialog = false // true [2021.1.28] 팝업을 띄우지 않도록 한다.

    // 일반회원의 첫 로그인시 토큰을 물고온다. 아래에서 처리
    if (this.$route.query && this.$route.query.token) { // this.$route.query 는 항시 있으니 참이구만
      //
      this.loginWithToken(this.$route.query.token)
      //
    } else if (!this.$store.state.ui.dbcode) {
      // 첫 로그인이 아니라면 여기로 와선 안된다. - 이걸 따질 방법이 마땅치 않아 dbcode로 대치함
      this.redirect('/')
      //
    }
  },

  methods: {
    dummy () {
    },

    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },

    // [토큰을 백엔드에서 넘겨받아 로그인 처리하기]
    async loginWithToken (token) {
      try {
        if (token) {
          // 로그인 처리 : 토큰 저장 + 토큰을 푼 유저정보를 저장
          await this.$store.dispatch('LOGIN', { token })

          // 중요: [2021.1.28] 첫로그인시 이용가이드 페이지로 리디렉션한다.
          this.redirect('/guide')
        } else {
          throw new Error('[In member/firstLogin]Token Not Reponsed : [401]')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    }
  },
  props: {
    source: String
  }
}
</script>
